@import 'src/commons/styles/abstracts';

.Landing {
  &__heading {
    font-weight: 300;
    color: color(grey);
    @include order-1;
    margin-bottom: 4.2rem;

    &--welcome {
      display: block;
      text-align: center;
    }

    &--name {
      font-weight: bold;
      display: block;
      text-align: center;
    }
  }

  :not(.Card__subtitle) > p {
    @include margin-bottom($s);
  }

  /** used for card **/
  ul {
    list-style: none;
  }
}
