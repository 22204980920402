@import 'src/commons/styles/List';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #f5f4f0;
  font-family: 'Roboto', sans-serif;
}

button,
input,
textarea,
select {
  font-family: 'Roboto', sans-serif;
}

main {
  min-height: 75vh;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

// Hide the 'x' icon deleting the input's content and the default "eye" icon to reveal the password, added by Edge/IE
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

em {
  font-style: normal;
  font-weight: bold;
}

hr {
  border: 0;
  border-bottom: 0.1rem solid color(grey, 15);
  @include margin-bottom($m);
}
