@import 'src/commons/styles/abstracts';

.WhatsNewAnnouncement {
  header {
    @include padding-top($xl);
    @include padding-right($m, $xl);
  }

  h3 {
    @include order-2;
    @include margin-top($l);
    color: color(brandred);
    font-weight: 500;
  }

  &__ack-button {
    text-align: center;

    &--50 {
      @include bp($bp-2) {
        width: 50%;
      }
    }

    &--100 {
      @include bp($bp-2) {
        width: 100%;
      }
    }
  }

  .Card__body {
    @include bp($bp-1) {
      padding-bottom: size($s, $bp-1) !important;
    }

    @include bp($bp-2) {
      padding-bottom: size($l, $bp-2) !important;
    }
  }

  .Badge {
    border-radius: 3.6rem;
  }

  .Badge__icon-before {
    top: -5px;
  }

  &__history-link {
    text-align: left;
    border-top: 0.1rem solid color(grey, 15);
    border-bottom: 0.1rem solid color(grey, 15);
    @include padding-bottom($s);
    @include padding-top($s);
    @include margin-bottom($l);

    a {
      padding-top: 0.4rem;
      width: 100%;
    }
  }
}
