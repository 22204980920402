@import 'src/commons/styles/abstracts';

.ApplicationFormWizard {
  &__body {
    top: 5rem;
    position: relative;
  }

  &__button {
    &:first-of-type {
      @include margin-top($xl);
    }
    &:not(:last-of-type) {
      @include margin-bottom($m);
    }
  }
}
