@import 'src/commons/styles/abstracts';

.Announcement {
  li {
    list-style: none;
  }

  dt {
    color: color(brandgreen) !important;
  }

  &__info {
    &--locked {
      color: color(brandred);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__soon {
    li {
      list-style: none;
      &:last-child {
        @include padding-bottom($s);
        border-bottom: 0.1rem solid color(grey, 15);
      }
    }

    button {
      @include margin-bottom($m, 0);
      @include margin-right(0, $l);
      @include margin-top($m, 0);

      &:last-child {
        margin-bottom: 0;
        margin-top: 0;
        @include bp($bp-2) {
          @include margin-top($m);
        }
      }
    }

    &__p {
      @include margin-top($s);
    }
  }

  &__broken {
    button {
      @include margin-bottom($m, 0);
      @include margin-right(0, $l);

      &:last-child {
        margin-bottom: 0;
        @include bp($bp-2) {
          @include margin-top($m);
        }
      }
    }

    p {
      &:not(:last-of-type) {
        @include padding-bottom($s);
        border-bottom: 0.1rem solid color(grey, 15);
      }
    }
  }

  &__locked {
    p {
      @include margin-bottom($s);
    }
  }

  &__date {
    display: block;
  }

  & hr {
    @include margin-top($m);
  }

  &__laundry-day-remote-opening {
    @include padding-top($m);
  }
}
