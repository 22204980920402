@import 'src/commons/styles/abstracts';

.Template {
  &__heading {
    @include order-2;
    @include margin-top($xxl);
    @include margin-bottom($xl);
    font-weight: 300;
    text-align: center;

    &-request {
      @extend .Template__heading;
      @include margin-top($xxxl);
      @include margin-bottom($l);
    }

    &--confirmation {
      @extend .Template__heading;
      color: color(brandgreen);
    }
  }

  &__title {
    display: block;
    @include order-2;
    @include margin-bottom($xl);
    @include margin-top($xxxl);
    font-weight: 300;
  }

  &__subtitle {
    display: block;
    @include copy;
    @include margin-bottom($xl);
    @include margin-top($xxxl);
    font-weight: 300;
  }

  &__text {
    display: block;
    @include copy;
  }

  &__dropdown {
    @include margin-bottom($xxxl);
  }

  &__clause {
    margin-bottom: 10rem;

    .Link {
      @include margin-bottom($l);
    }
  }

  &__document {
    &:first-child {
      @include margin-top($xl);
    }

    @include margin-bottom($l);

    &:last-child {
      @include margin-bottom($xl);
    }
  }

  &__dl {
    display: flex;
    flex-direction: column;
    @include copy;
    @include margin-bottom($xl);

    dt {
      font-weight: bold;

      @include bp($bp-2) {
        flex: 0 0 22rem;
      }

      @include padding-right($l);
    }
  }

  &__dl-card {
    @include copy;
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
      @include margin-top($l);
    }

    &:not(:last-of-type) {
      @include padding-bottom($l);
      border-bottom: 1px solid color(grey, 15);
    }

    dt {
      font-weight: bold;

      @include bp($bp-2) {
        flex: 0 0 22rem;
      }

      @include padding-right($l);
    }
  }

  &__ul {
    list-style: none;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &__paragraph {
    @include copy;
    @include margin-bottom($xl);
    text-align: center;

    &-separation {
      @include margin-bottom($xxl);
    }

    &-close {
      @include margin-bottom($s);
    }

    &-end {
      margin-bottom: 0;
    }
  }

  &__collapsible-container {
    &:not(:first-child) {
      @include margin-top($xl);
    }
  }

  &__collapsible {
    padding-bottom: 0;

    &--conditional {
      @include margin-top($xxl);
    }
  }

  &__icon--information {
    width: 100%;

    svg {
      display: flex;
      align-items: center;
    }
  }

  &__list {
    @include copy;
    padding-left: 1.7rem;
    list-style-position: outside;
  }

  &__card {
    @include margin-top($xxl);
  }

  &__list-card {
    @include copy;
    list-style: none;

    li:not(:last-of-type) {
      border-bottom: 1px solid color(grey, 15);
      @include padding-bottom($s);
    }
  }

  &__article {
    @include margin-bottom($xxxl);
  }

  &__fieldset {
    &:not(:first-child) {
      @include margin-top($xl);
    }
  }

  &__info-list:not(:first-child) {
    @include margin-top($xs);
  }

  &__info-list-margins:not(:first-child) {
    @include margin-top($xl);
  }

  &__sections {
    @include margin-bottom($xxxl);
  }

  &__checkbox {
    @include margin-top($xxl);
  }

  &__explanation {
    @include label;
    @include margin-top($xs);
    @include margin-left($xl);
    white-space: pre-wrap;
  }

  &__icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg:first-of-type {
      @include margin-bottom($s);
    }
  }
}

.Fieldset__label {
  svg {
    @include margin-right($l);
  }

  .Template__fieldset-dl {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    dt {
      @include label;
    }
  }
}

@keyframes confirmation {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes confirmationDocument {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 30%, 0 30%);
    transform: translateY(1.5rem);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transform: translateY(0);
  }
}

@keyframes confirmationFlapLine {
  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes confirmationFlapOutside {
  0% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(90deg);
  }
}

@keyframes confirmationFlapInside {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes confirmationWriting {
  0% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}
