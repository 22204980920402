@import 'src/commons/styles/abstracts';

.InBoxAnnouncement {
  &__hyperlink-container {
    border-top: 0.1rem solid color(grey, 15);
    @include padding-top($m);
  }

  &__hyperlink {
    width: 100%;
  }

  .Data {
    @include margin-bottom($m);
  }

  &__description {
    white-space: pre-wrap;
    @include margin-bottom($m);

    &-link {
      @include margin-top($s);
    }
  }
}
