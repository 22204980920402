@import 'src/commons/styles/abstracts';

.WizardOverlay {
  &__section {
    position: relative;
    top: 5rem;

    .Section__title-box {
      margin-bottom: 0 !important;
    }
  }

  &__paragraph {
    @include margin-bottom($m);
  }

  &__button {
    @include margin-bottom($xs);
  }

  svg {
    margin-bottom: 100rem;
  }
}
