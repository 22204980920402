@import 'src/commons/styles/abstracts';

.RentalAccountAnnouncement {
  &__paymentsMissing {
    h3 {
      @include copy;
      color: color(brandred, 100);
    }

    &:not(:last-child) .RentalAccountAnnouncement__hyperlink-container {
      border-bottom: 0.1rem solid color(grey, 15);
      @include padding-bottom($xs);
      @include margin-bottom($m);
    }

    &:last-child {
      .Link {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  &__hyperlink-container {
    border-top: 0.1rem solid color(grey, 15);
    @include padding-top($m);
  }

  &__hyperlink {
    width: 100%;
  }

  .Data {
    @include margin-bottom($m);
  }

  &__description {
    white-space: pre-wrap;
    @include margin-bottom($m);

    &-link {
      @include margin-top($s);
    }
  }
}
