@import 'src/commons/styles/abstracts';

.ApplicationFormLanding {
  &__body {
    top: 4rem;
    position: relative;
  }

  &__list {
    list-style: none;

    li {
      &:not(:last-child) {
        border-bottom: 0.1rem solid color(grey, 15);

        .Link {
          padding-bottom: size($s, $bp-1) !important;
        }
      }

      &:not(:first-child) {
        .Link {
          padding-top: size($s, $bp-1) !important;
        }
      }

      .Link {
        width: 100%;
      }
    }
  }

  &__collapsible {
    @include margin-bottom($m);

    .Collapsible3__button--title {
      @include copy;
      font-weight: 400;
    }
  }

  &__section {
    h2 {
      font-weight: 400;
      text-align: left;
    }
    .Section__title-box {
      padding-bottom: 0;
    }
  }

  &__description {
    @include copy;
    color: color(grey, 60);
  }

  &__emptyState {
    text-align: center;
  }

  &__heading--h4 {
    @include label;
    font-weight: 400;
    @include margin-bottom($xs);
  }

  &__button {
    @include margin-top($l);
  }
}
