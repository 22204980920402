@import 'src/commons/styles/abstracts';

.ApplicationFormDetails {
  &__body {
    position: relative;
    top: 5rem;
  }

  &__heading {
    @include order-1;
    @include padding-top($xxl);
    font-weight: 400;
  }

  &__status-card {
    padding-bottom: 0.8rem !important;
  }

  &__information {
    @include margin-bottom($m);
  }

  &__ul {
    list-style: none;
  }

  &__dl {
    @include copy;

    &:not(:last-of-type) {
      @include padding-bottom($m);
    }

    &:not(:first-of-type) {
      border-top: 1px solid color(grey, 15);
      @include padding-top($m);
    }
  }

  &__history {
    &:first-of-type {
      @include padding-top($m);
    }

    &:last-of-type {
      @include padding-bottom($m);
    }
  }

  &__history,
  &__heading {
    position: relative;

    &:after {
      content: '';
      display: block;
      background: color(white);
      width: 101vw;
      height: 100%;
      position: absolute;
      left: 50%;
      margin-left: -50vw;
      top: 0;
      z-index: -1;
      transform: translateX(-0.7rem);
    }
  }

  &__download {
    @media (max-width: 290px) {
      @include margin-top($l);
    }
    @media (min-width: 290px) {
      @include margin-top($s);
    }

    &--expired {
      @include margin-top($xs);
    }
  }

  &__button--finish-hb {
    @include margin-top($m);
  }
}
