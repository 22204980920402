@import 'src/commons/styles/abstracts';

.ApplicationFormHistory {
  &__status {
    .Data {
      &__key {
        @include copy;
        font-weight: 700;
      }
      &__value {
        @include copy;

        & > * {
          &:not(:last-child) {
            @include margin-bottom($s);
          }
        }
      }
    }
  }

  &__document {
    &:first-child {
      @include margin-top($xl);
    }

    dd {
      margin-left: -5.5rem !important;
    }
  }

  &__download {
    @include margin-top($xs);
  }
}
