@import 'src/commons/styles/abstracts';

.NavigationHeader {
  .Link__icon--before {
    margin: 0 0.5rem 0 0.9rem;

    /* this is for samsung galaxy fold */
    @media (max-width: 290px) {
      @include margin-right($xxs);
    }
  }

  background: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  @include padding($xs);

  width: 100vw;
  z-index: 100;
  box-shadow: 0rem -0.4rem 0.8rem rgba(0, 0, 0, 0.05);

  &__title {
    @include copy;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .Link__child {
    color: #3482ff; // that's the ios linkColor
  }

  svg.Link__icon {
    margin-top: 0;
    path {
      stroke: #3482ff;
    }
  }
}
