@import 'src/commons/styles/abstracts';

.ApplicationFormPreSelection {
  &__body {
    top: 5rem;
    position: relative;
    height: 110vh;
  }

  &__button {
    @include margin-top($s);
  }
}
